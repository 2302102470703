<template>
  <page-content>

    <common-table
      ref="table"
      path="web/app-forum-topic"
      :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
      </template>

      <template slot="enable" slot-scope="{record}">
        <a-tag v-if="record.enable" color="green">显示</a-tag>
        <a-tag v-else color="#999">不显示</a-tag>
      </template>

      <template slot="userAllow" slot-scope="{record}">
        <a-tag v-if="record.userAllow" color="green">允许</a-tag>
        <a-tag v-else color="#999">不允许</a-tag>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>

      </template>

    </common-table>

    <forum-topic-edit ref="editDrawer" @success="getList"></forum-topic-edit>

  </page-content>

</template>
<script>

import ForumTopicEdit from './ForumTopicEdit'

export default {
  components: {
    ForumTopicEdit,
  },
  data () {
    return {

    }
  },

  computed: {
    columns () {
      return [
        {
          title: '图片',
          dataIndex: 'logo',
          scopedSlots: {customRender: 'logo'},
        },
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '简介',
          dataIndex: 'des',
        },
        {
          title: '是否显示',
          dataIndex: 'enable',
          scopedSlots: {customRender: 'enable'},
        },
        // {
        //   title: '允许发帖',
        //   dataIndex: 'userAllow',
        //   scopedSlots: {customRender: 'userAllow'},
        // },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          width: 120
        }
      ]
    }
  },
  methods: {

    add(){
      this.$refs.editDrawer.show()
    },
    view (record) {
      this.$router.push("/forum/post?topicId="+record.id)
    },
    edit (record) {
      this.$refs.editDrawer.show(record)
    },
    remove (record) {
      var that = this
      this.$confirm({
        title: '是否删除该话题?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('web/app-forum-topic/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },

    getList () {
      this.$refs.table.getData()
    },

  }
}
</script>
