var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-content',[_c('common-table',{ref:"table",attrs:{"path":"web/app-forum-topic","columns":_vm.columns},scopedSlots:_vm._u([{key:"logo",fn:function(ref){
var record = ref.record;
return [_c('a-avatar',{attrs:{"shape":"square","size":60,"src":record.logo}},[_vm._v(_vm._s(record.title))])]}},{key:"enable",fn:function(ref){
var record = ref.record;
return [(record.enable)?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("显示")]):_c('a-tag',{attrs:{"color":"#999"}},[_vm._v("不显示")])]}},{key:"userAllow",fn:function(ref){
var record = ref.record;
return [(record.userAllow)?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("允许")]):_c('a-tag',{attrs:{"color":"#999"}},[_vm._v("不允许")])]}},{key:"operation",fn:function(ref){
var record = ref.record;
return [_c('action-view',{on:{"click":function($event){return _vm.view(record)}}}),_c('action-edit',{on:{"click":function($event){return _vm.edit(record)}}}),_c('action-delete',{on:{"click":function($event){return _vm.remove(record)}}})]}}])},[_c('template',{slot:"search"},[_c('a-button',{attrs:{"ghost":"","type":"primary"},on:{"click":_vm.add}},[_vm._v("新增")])],1)],2),_c('forum-topic-edit',{ref:"editDrawer",on:{"success":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }